import { inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null;

  private readonly storage = inject(Storage);

  constructor() {
    this.init();
  }

  async init(): Promise<void> {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  set(key: string, value: any): Promise<void> {
    return this._storage?.set(key, value);
  }

  get(key: string): Promise<any> {
    return this._storage?.get(key);
  }

  remove(key: string): Promise<void> {
    return this._storage?.remove(key);
  }

  clear(): Promise<void> {
    return this._storage?.clear();
  }

  keys(): Promise<string[]> {
    return this._storage?.keys();
  }
}
