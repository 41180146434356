import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { AlertController, IonContent, IonRouterOutlet, NavController, Platform, ToastController } from '@ionic/angular/standalone';
import { ToastOptions } from '@ionic/core/dist/types/components/toast/toast-interface';
import { environment } from '../../../../mobile/src/environments/environment';
import { firstValueFrom, map } from 'rxjs';
import { Position } from '@capacitor/geolocation/dist/esm/definitions';
import { IAppLaunchConstant } from '@rdlabo/odss-mobile-api/src/app.api';
import { StorageService } from './storage.service';
import { defaultAppLaunchConstant, IDatabase } from '../../../../mobile/src/config/constant';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  appLaunchConstant: IAppLaunchConstant = defaultAppLaunchConstant;
  position: Position;
  private readonly alertCtrl = inject(AlertController);
  private readonly http = inject(HttpClient);
  private readonly platform = inject(Platform);
  private readonly toastCtrl = inject(ToastController);
  private readonly storage = inject(StorageService);

  constructor() {}

  setConstant(constant: IAppLaunchConstant) {
    this.appLaunchConstant = constant;
  }

  async changePositionToShop(position: Position): Promise<number> {
    // フタバ水栓は1110のみ
    if ((localStorage.getItem('apiKey') as IDatabase) === 'futaba') {
      return 1110;
    }

    if (position) {
      if (position.coords.latitude > 34.07 && position.coords.latitude < 34.08 && position.coords.longitude > 134.5 && position.coords.longitude < 134.6) {
        return 1110;
      } else if (
        position.coords.latitude > 34.06 &&
        position.coords.latitude < 34.07 &&
        position.coords.longitude > 134.3 &&
        position.coords.longitude < 134.5
      ) {
        return 1111;
      } else {
        await this.presentToast({
          message: `位置情報から店舗情報が選択できませんでした. [${position.coords.latitude}/${position.coords.longitude}]`,
        });
      }
    }

    if (!position) {
      const alert = await this.alertCtrl.create({
        header: '位置情報が取得できませんでした',
        message: 'プルダウンから手動で店舗名を選択ください',
        buttons: ['閉じる'],
      });
      await alert.present();
    }

    return 1110; // default
  }

  async getUserName(): Promise<string> {
    return firstValueFrom(
      this.http
        .get<{
          name: string;
        }>(environment.api() + 'auth/user')
        .pipe(map((data) => data.name)),
    );
  }

  async alertClose(AlertOption: { header: string; message: string }) {
    const alert = await this.alertCtrl.create({
      header: AlertOption.header,
      message: AlertOption.message,
      buttons: ['閉じる'],
    });
    await alert.present();
    return alert.onWillDismiss<void>();
  }

  async alertConfirm(AlertOption: { header: string; message: string }): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>((resolve) => (resolveFunction = resolve));
    const alert = await this.alertCtrl.create({
      header: AlertOption.header,
      message: AlertOption.message,
      buttons: [
        {
          text: 'キャンセル',
          role: 'cancel',
          handler: () => resolveFunction(false),
        },
        {
          text: '同意する',
          handler: () => resolveFunction(true),
        },
      ],
    });
    await alert.present();
    return promise;
  }

  /**
   * キーボード出現時にContentを最下部に移動する
   */
  scrollForShowKeyboard(content: IonContent): void {
    const intervalId = setInterval(() => content.scrollToBottom(), 8);
    setTimeout(() => clearInterval(intervalId), 420);
  }

  /**
   * ハーフモーダル利用時にキーボードが出現したら全モーダルに変更
   */
  changeHalfModalWithKeyboard(e): void {
    const ionBackDrop = document.querySelector('.half-modal');
    if (e.hasOwnProperty('keyboardHeight') && e.keyboardHeight > 0) {
      ionBackDrop.classList.add('half-modal-keyboard');
    } else {
      ionBackDrop.classList.remove('half-modal-keyboard');
    }
  }

  /**
   * 戻るボタンの再現（Confirm時などに利用）
   */
  backButtonMethod(routerOutlet: IonRouterOutlet, navCtrl: NavController, defaultHref: string): void {
    if (routerOutlet && routerOutlet.canGoBack()) {
      navCtrl.setDirection('back');
      routerOutlet.pop();
    } else if (defaultHref != null) {
      navCtrl.navigateBack(defaultHref);
    }
  }

  /**
   * Toastの共通化
   */
  async presentToast(options: ToastOptions): Promise<void> {
    if (this.platform.is('hybrid')) {
      Haptics.impact({ style: ImpactStyle.Light });
    }

    options = Object.assign(
      {
        position: 'top',
        color: 'dark',
        duration: 5000,
        swipeGesture: 'vertical',
        buttons: ['閉じる'],
      },
      options,
    );
    const toast = await this.toastCtrl.create(options);
    await toast.present();
  }

  /**
   * 連想配列を順番をつけて結合
   */
  ArrayConcatById<T>(arrayOld: T[], arrayNew: T[], key: keyof T, order: string = 'DESC', secondaryKey: keyof T = null): T[] {
    if (!arrayNew.length && !arrayOld.length) return [];

    const lead = arrayNew[0][key] as number;
    const last = arrayNew[arrayNew.length - 1][key] as number;

    arrayOld = arrayOld.filter((vol) => {
      return (
        (lead > last && ((vol[key] as number) >= lead || (vol[key] as number) <= last)) ||
        (lead < last && ((vol[key] as number) <= lead || (vol[key] as number) >= last)) ||
        (lead as number) === (last as number)
      );
    });

    let old: T[];
    if (secondaryKey !== null) {
      old = arrayOld.filter((vol) => {
        return !arrayNew.some((element) => {
          return element[secondaryKey] === vol[secondaryKey];
        });
      });
    } else {
      old = arrayOld;
    }

    const oldData = old.filter((vol) => {
      return !arrayNew.some((element) => {
        return element[key] === vol[key];
      });
    });
    let data = arrayNew.concat(oldData);

    // デフォは降順(DESC)
    let ord = -1;

    if (order === 'ASC') {
      // 指定があれば昇順(ASC)
      console.log('ASC');
      ord = 1;
    }

    data = data.sort((a, b) => {
      const x = a[key] as number;
      const y = b[key] as number;
      if (x > y) {
        return ord;
      }
      if (x < y) {
        return ord * -1;
      }
      return 0;
    });

    return data;
  }

  /**
   * HTTPリクエストのレスポンス内容を確認
   */
  async errorResponse(error, alertCheck: boolean = false): Promise<void> {
    let errorTitle: string = null;
    let errorText: string = null;
    switch (error.status) {
      case 0:
      // errorTitle = 'インターネットの接続がありません';
      // errorText = 'アクセスができませんでした。インターネットへの接続を確認してください。';
      // break;
      case 401:
      // break;
      case 404:
        errorTitle = '404 Not Found';
        errorText = 'アプリのバージョンが低いです。AppStoreより最新版をインストール下さい';
        break;
      case 500:
        errorTitle = '500 Internal Server Error';
        errorText = 'アクセスができませんでした。インターネットへの接続を確認してください。';
        break;
      case 503:
        errorTitle = '503 Service Unavailable';
        errorText = 'サーバにアクセスが集中しているためアクセスができませんでした。しばらく時間を置いてから再アクセス下さい。';
        break;
      default:
        // errorTitle = 'Server Error';
        // errorText = 'アクセスができませんでした。'+ error.status +'番のエラーです。';
        break;
    }

    if (errorTitle && !alertCheck) {
      const alert = await this.alertCtrl.create({
        header: errorTitle,
        message: errorText,
        buttons: ['閉じる'],
      });
      alert.present();
    }
  }
}
